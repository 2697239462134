<!--创建问诊单  问诊单-->
<template>
	<div class="consu-container">
		<v-consultation-header></v-consultation-header>
		<div class="consultation">
			<div class="consultation-content">
				<consultation ref="consultation" @notify="getChecked"></consultation>
			</div>
			<div class="btn flex f-jc-c f-ai-c" :class="{'disabled': !canNext}">
				<!-- <button @click="nextStep">下一步</button> -->
				<div @click="nextStep">
					<span>下一步</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import vConsultationHeader from './consultation-header';
import consultation from './consultation';

export default {
	name: 'CosulationTwo',
	data () {
		return {
			canNext: true
		};
	},
	components: {
		vConsultationHeader,
		consultation
	},
	mounted () {
		if (window.history && window.history.pushState) {
			history.pushState(null, null, document.URL);
			window.addEventListener('popstate', this.onBack, false);
		}
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	created () {

	},
	computed: {
		...mapGetters({
			patientList: 'getPatientList',
			stepOneVal: 'getStepOne'
		})
	},
	methods: {
		onBack () {
			this.$root.replace({
				path: '/consultation-one',
				query: { ...this.$route.query }
			});
		},
		nextStep () {
			// TODO: 这里需要增加，判断有没有 没有做的题，如果有，则不可提交，按钮置灰
			this.canNext = this.$refs.consultation.checked;
			if (this.canNext) {
				this.$refs.consultation.commit();
				this.$root.replace({
					path: '/consultation-three',
					query: { ...this.$route.query }
				});
			}
		},
		getChecked (checked) {
			this.canNext = checked;
		}
	}
};
</script>
<style lang="less" scoped>
	.consu-container {
		// padding-bottom: 1px;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
	}
	.consultation {
		// margin-bottom: 72px;
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
	.consultation-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		overflow: auto !important;
	}
	.btn {
		// position: fixed;
		height: 52px;
		width: 100%;
		background-color: #FFFFFF;
		display: flex;
		align-items: center;
		// justify-content: center;
		div {
			// width: 339px;
			width: 100%;
			margin: 0 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
			border-radius: 21px;
			border: none;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
		.enable {
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%) !important;
			opacity: 1 !important;
		}
	}
	.disabled {
		opacity: 0.5 !important;
	}
</style>